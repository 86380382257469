@import '../../styles/common.scss';

.formWrapper {
  @include desktop {
    max-width: 600px;
    margin: 40px auto 80px;
  }
  @include tablet {
    margin: 40px 40px 80px;
  }
  @include sp {
    margin: mob(32) 16px mob(80);
  }
}

.formButtonWrapper {
  display: flex;
  justify-content: center;
  @include non-sp {
    margin-top: 32px;
  }
  @include sp {
    margin-top: mob(32);
  }
}
