@import '../../styles/common.scss';

.signInLink {
  @include use-europa-yu-gothic;
  @include type-label-text;
    color: var(--notify-red);
    margin-top: 8px;
    a {
      text-decoration: underline;
      color: var(--notify-red);
    }
}

.formWrapper {
  @include desktop {
    max-width: 600px;
    margin: 40px auto 80px;
  }
  @include tablet {
    margin: 40px 0 80px;
  }
  @include sp {
    margin: mob(32) 0 mob(80);
  }
}

.formButtonWrapper {
  display: flex;
  justify-content: center;
  @include non-sp {
    margin-top: 32px;
  }
  @include sp {
    margin-top: mob(32);
  }
}

.formLabel {
  @include use-yu-gothic;
  display: block;
  text-align: left;
  font-weight: bold;
  color: var(--text-black);
  @include non-sp {
    @include type-text;
  }
  @include sp {
    @include type-text;
  }
}

.formError {
  display: block;
  text-align: left;
  @include use-yu-gothic;
  @include type-label-text;
  color: var(--notify-red);
  @include non-sp {
    margin-top: 16px;
  }
  @include sp {
    margin-top: mob(16);
  }
}

.formGroup {
  &:not(:first-child) {
    @include non-sp {
      margin-top: 32px;
    }
    @include sp {
      margin-top: mob(32);
    }
  }
}

.formGroupBody {
  @include non-sp {
    margin-top: 12px;
  }
  @include sp {
    margin-top: mob(12);
  }
}

.input {
  transition: all .2s ease;
  background: var(--bg-gray-0);
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  @include type-text;
  @include use-yu-gothic;
  color: var(--text-black);
  @include non-sp {
    height: 72px;
    border-radius: 8px;
    padding: 0 16px;
  }
  @include sp {
    height: mob(56);
    border-radius: mob(8);
    padding: 0 mob(16);
  }

  &::placeholder {
    @include type-text;
    color: var(--text-gray-2);
  }
  &:focus {
    background: var(--bg-white);
    border: 1px solid var(--green-1);
  }
  &:hover {
    border: 1px solid var(--green-1);
  }
  &.error {
    border: 1px solid var(--notify-red);
  }
}

.link {
  color: var(--text-blue-1);
  text-decoration: underline;
}

.selectWrapper {
  position: relative;

  .select {
    appearance: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
    cursor: pointer;
  }
}

.emailAnnotationArea {
  border: 1px solid #C38383;
  padding: 16px;
  margin-top: 12px;
  background-color: #FBF7F7;

  .emailAnnotation {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.3px;
    text-align: left;
  }
}

.birthdayForm {
  display: flex;
  align-items: center;
  gap: 8px;

  .birthdayFormInputWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .birthdayFormInput {
    flex-grow: 1;
  }

  .birthdayFormYear {
    flex-basis: 40%;
  }
  .birthdayFormMonth {
    flex-basis: 30%;
  }
  .birthdayFormDay {
    flex-basis: 30%;
  }
}
