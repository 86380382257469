@import '../../styles/common.scss';

.cardListItem {
  cursor: pointer;
  background: white;
  border: 1px solid var(--border-gray-0);
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  flex-grow: 0;
  flex-shrink: 0;
  @include desktop {
    width: 327px;
  }
  @include tablet {
    width: 327px;
  }
  @include sp {
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include desktop {
      height: 51px;
    }
    @include tablet {
      height: 51px;
    }
    @include sp {
      padding: mob(8) 0;
    }
  }
  .datetime {
    margin-left: 8px;

    &.featured {
      @include desktop {
      }
      @include tablet {
        margin-top: 3px;
      }
      @include sp {
        margin-top: mob(3);
      }
    }
    .date {
      @include type-title-base(11);
      letter-spacing: 2px;
      @include non-sp {
        margin-right: 8px;
      }
      @include sp {
        margin-right: mob(5);
        letter-spacing: mob(1.2);
      }
    }
    .time {
      @include use-europa;
      @include type-text-custom(14);
      font-weight: bold;
      @include non-sp {
      }
      @include sp {
        margin-top: mob(-4);
      }
    }
  }
}
