@import '../../styles/common.scss';

.changeWeek {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;

  @include sp {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.changeWeek button {
  color: var(--primary);
  border-color: var(--primary);
  border: 1px solid;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 60px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.changeWeek button:disabled {
  border-color: var(--button-secoundary-border);
  color: var(--button-secoundary-text)
}

.previous {
  padding-left: 12px;
  padding-right: 24px;
}

.next {
  padding-left: 24px;
  padding-right: 12px;
}

.disabled {
  opacity: 0.3;
}

.calendarHeader {
  position: sticky;
  top: 0;
  border: 1px solid var(--input-border);
  border-right: none;
  background: #fff;
}

.monthRow {
  border-bottom: 1px solid var(--input-border);
  border-right: 1px solid var(--input-border);
  display: flex;
}

.monthPlaceHolder {
  width: 60px;
}

.month {
  flex: 1;
  text-align: center;
}

.dateDefinition {
  width: 60px;
  border-right: 1px solid var(--input-border);
  font-size: 14px;
  text-align: center;
  padding: 12px;
  line-height: 1.2;
}

.dateRow {
  display: flex;
}

.dayOfWeek {
  border-right: 1px solid var(--input-border);
  flex: 1;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date {
  font-size: 14px;
  display: block;
  font-weight: bold;
}

.saturday {
  color: var(--blue);
}

.holiday {
  color: var(--pink-darken);
}

.sunday {
  color: var(--pink-darken);
}

.timeTable {
  text-align: center;
  border-left: 1px solid var(--input-border);
}

.time {
  border-right: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  width: 60px;
}

.timeRow {
  flex: 1;
}

.calendarContent {
  display: flex;
}

.calendarStatus {
  border-right: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  line-height: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray {
  color: var(--text-gray-3);
  font-size: 12px;
}

.none {
  color: var(--text-gray-3);
}

.statusRemarks {
  margin-top: 24px;
  background: var(--bg-gray-0);
  padding: 12px 4px 8px;
}

.statusRemarks dl {
  background: var(--bg-gray-0);
  padding: 4px;
}

.statusRemarks dl > div {
  display: flex;
  margin-bottom: 4px;
  align-items: start;
}

.statusRemarks dl dt {
  flex: 1;
}

.statusRemarks dl dd {
  flex: 4;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}

