@import '../../styles/common.scss';

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  @include use-europa-yu-gothic;
  @include type-label-text;
  font-weight: bold;
  cursor: pointer;
  flex-shrink: 0;
  &.primary {
    background-color: var(--text-black);
    color: white;
    .loader {
      path {
        fill: white;
      }
    }
  }
  &.normal {
    border: 1px solid var(--text-black);
    color: var(--text-black);
    .loader {
      path {
        fill: var(--text-black);
      }
    }
  }
  &.multi-creator {
    background-color: var(--multi-creator-lp-red);
    color: white;
    .loader {
      path {
        fill: white;
      }
    }
  }
  &.loading {
    cursor: progress;
  }
  &.disabled {
    background-color: var(--border-gray-0);
    color: white;
    cursor: not-allowed;
  }
  @include non-sp {
    width: 91px;
    height: 36px;
    border-radius: 4px;
  }
  @include sp {
    width: mob(91);
    height: mob(36);
    border-radius: mob(4);
  }
}

.loader {
  @include non-sp {
    width: 12px;
    height: 12px;
  }
  @include sp {
    width: mob(12);
    height: mob(12);
  }
}
