@import '../../styles/common.scss';

.reservationButton {
  @include use-europa-yu-gothic;
  background: var(--text-black);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include type-text;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  @include non-sp {
    width: 327px;
    height: 60px;
    &:hover {
      opacity: 0.8;
    }
  }

  @include sp {
    width: mob(325);
    height: mob(53);
  }
}

.disabled {
  background: var(--bg-gray-0);
  pointer-events: none;
}