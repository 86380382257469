@import '../../styles/common.scss';

.fancyDate {
  margin-right: 8px;
  @include use-europa;
  font-weight: bold;
}
.weekdayLabel {
  @include use-yu-gothic;
  margin-left: .2em;
}

.saturday {
  color: var(--blue-1);
}

.sunday {
  color: var(--pink-1);
}
