@import '../../styles/common.scss';

.reservationStepHeader {
  background: var(--primary);
  color: white;
  @include use-europa-yu-gothic;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  @include desktop {
    width: 100%;
    padding: 20px 32px 24px;
  }
  @include tablet {
    width: 100%;
    padding: 20px 32px 24px;
  }
  @include sp {
    padding: mob(12);
  }
}

.stepText {
  font-family: var(--font-family-europa-yu);
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  background: white;
  color: var(--primary);
  @include type-text-custom(17);
  font-weight: bold;
  @include non-sp {
    padding: 0 16px;
  }
  @include sp {
    padding: 0 mob(12);
  }
}

.headerBody {
  text-align: center;
  color: white;
  @include type-text-custom(15);
  font-weight: bold;
  @include non-sp {
    margin-top: 8px;
  }
  @include sp {
    margin-top: mob(8);
  }
}

.reservation-step-body {
  overflow: hidden;
  @include desktop {
    width: 1000px;
    margin: 0 auto;
  }
}

.reservation-step-body-inner {
  color: var(--text-black);
  @include desktop {
    padding: 1px 0;
  }
  @include tablet {
    padding: 1px 32px;
  }
  @include sp {
    text-align: left;
    padding: 1px mob(24);
  }
}

.reservationStepSubHeader {
  @include use-europa-yu-gothic;
  font-weight: bold;
  letter-spacing: .07rem;
  @include non-sp {
    text-align: center;
    margin-top: 0;
    margin-bottom: 32px;
  }
  @include sp {
    text-align: left;
    margin: mob(32) mob(24) mob(24);
  }
}