@import '../../styles/common.scss';

.badgeWrapper {

  @include type-label-text;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationBadge {
  @include use-yu-gothic;
  font-weight: bold;
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: center;
  background: #EEF6F8;
  color: #6B9AAA;
  @include non-sp {
    padding: 4px 12px;
  }
  @include sp {
    padding: 8px;
  }
  @include type-tiny-text;
  line-height: 1;
  .online-icon {
    @include non-sp {
      margin-left: -6px;
      height: 14px;
    }
    @include sp {
      margin-left: mob(-10);
      height: mob(14);
    }
  }
}