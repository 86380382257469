@import '../../styles/common.scss';

.reservationNote {
  text-align: left;
  @include use-europa-yu-gothic;
  @include type-tiny-text;
  color: var(--text-black);
  @include non-sp {
    margin-top: 52px;
  }
  @include sp {
    margin-top: mob(52);
  }
}

.link, .link:visited, .link:active {
  color: var(--text-black);
  text-decoration: underline;
}

